<template>
  <v-card v-if="componentLoaded" class="follows">
    <v-card-actions class="pb-0">
      <v-btn class="back" text :to="{name: 'profile', params: {account: routeAccount}}" exact>
        <v-icon left>{{ icon.mdiArrowLeft }}</v-icon>
        Back to profile
      </v-btn>
    </v-card-actions>
    <v-card-text class="py-0">
      <v-row
        align="center"
        justify="center"
        class="counts"
      >
        <v-col v-if="isSeller">
          <v-btn
            :to="{name: 'followers', params: {account: routeAccount}}"
            class="caption"
            outlined
            tile
            block
          >
            <div class="title">{{ followers.length }}</div> followers
          </v-btn>
        </v-col>
        <v-col>
          <v-btn
            :to="{name: 'following', params: {account: routeAccount}}"
            class="caption"
            outlined
            tile
            block
          >
            <div class="title">{{ followed.length }}</div> following
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <v-list>
      <template v-for="(follow, index) in isFollowersView ? followers : followed">
        <template v-if="follow.followType == 'account'">
          <v-list-item
            v-if="getKeyFromLookup(follow, 'account')"
            :key="index"
            :to="{name: 'profile', params: {account: getKeyFromLookup(follow, 'account')}}"
          >
            <v-list-item-avatar :color="getKeyFromLookup(follow, 'mediaSrc') ? null : '#7070a0'" size="60">
              <v-img v-if="getKeyFromLookup(follow, 'mediaSrc')" :src="getKeyFromLookup(follow, 'mediaSrc')" :alt="getKeyFromLookup(follow, 'account')"></v-img>
              <span v-else class="white--text headline">{{ getKeyFromLookup(follow, 'account')[0].toUpperCase() }}</span>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="body-2 font-weight-bold">{{ getKeyFromLookup(follow, 'account') }}</v-list-item-title>
              <v-list-item-subtitle>{{ getKeyFromLookup(follow, 'legalName') }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
         </template>

        <template v-else>
          <v-list-item
            :key="index"
            :to="{name: 'tag-stream', params: {tag: follow.followID }}"
          >
            <v-list-item-avatar size="60" tile color="indigo">
              <span class="white--text display-2 font-weight-bold">#</span>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="body-2 font-weight-bold">Tag: {{ follow.followID }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </template>
    </v-list>
  </v-card>
</template>

<script>
  import { mapActions } from 'vuex'
  import { getImageURL } from '@/image-cdn'
  import { mdiArrowLeft } from '@mdi/js'

  export default {

    name: 'Follows',

    data() {
      return {
        icon: { mdiArrowLeft },
        componentLoaded: false,
        account: null,
        followers: [],
        followed: [],
        accountLookup: {}
      }
    },

    computed: {
      isFollowersView() { return this.$route.name == 'followers' },
      routeAccount() { return this.$route.params.account },
      isSeller() { return this.account.type === 'seller' },
    },

    mounted() {
      Promise
        .all([
          this.getAccount(this.routeAccount)
            .then(account => this.account = account),
          this.getFollowers(this.routeAccount)
            .then(qs => this.followers = qs.empty ? [] : qs.docs.map(doc => doc.data())),
          this.getFollowed(this.routeAccount)
            .then(qs => this.followed = qs.empty ? [] : qs.docs.map(doc => doc.data()))
        ])
        .then(() => {
          const accounts = [
            ...this.followers
              .map(f => f.follower),
            ...this.followed
              .filter(f => f.followType == 'account')
              .map(f => f.followID)
          ].filter(x => x)

          return accounts.length && this.getAccountsIn(accounts)
        })
        .then(accounts => {
          if (accounts) {
            accounts.forEach(ds => {
              const data = ds.data()
              this.accountLookup[ds.id] = {
                ...data,
                mediaSrc: data.media ? getImageURL(data.media, 60, 60, data.updated || data.created) : null
              }
            })
          }

          // Just in case something got deleted
          this.followers = this.followers
            .filter(f => f.followType != 'account' || this.getKeyFromLookup(f.follower, 'account'))
            .sort((a, b) =>
              a.followType !== b.followType ?
                a.followType > b.followType ?
                  1 : -1 :
                a.follower > b.follower ?
                  1 : -1
            )
          this.followed = this.followed
            .filter(f => f.followType != 'account' || this.getKeyFromLookup(f.followID, 'account'))
            .sort((a, b) =>
              a.followType !== b.followType ?
                a.followType > b.followType ?
                  1 : -1 :
                a.followID > b.followID ?
                  1 : -1
            )
          this.componentLoaded = true
        })
    },

    methods: {
      ...mapActions([
        'getFollowers',
        'getFollowed',
        'getAccount',
        'getAccountsIn'
      ]),
      getKeyFromLookup(follow, key) {
        const needle = typeof follow === 'string' ?
          follow :
          this.isFollowersView ?
            follow.follower :
            follow.followID
        const account = needle in this.accountLookup ? this.accountLookup[needle] : null

        return account && key in account ? account[key] : null
      }
    }
  }
</script>

<style lang="scss">
  .follows .counts {
    .v-btn.v-size--default {
      height: auto;

      .v-btn__content {
        flex-wrap: wrap;
        padding: 4px 8px 8px;

        .title {
          flex: 0 0 100%;
          text-align: center;
        }
      }
    }
  }
</style>
