<template>
  <v-card>
    <v-card-title>
      Create a post
    </v-card-title>
    <div v-if="!uploadValue">
      <v-alert v-if="error" type="error" class="ma-1">{{ error.message }}</v-alert>
      <MediaUpload
        ref="mediaUpload"
        :icon="icon.mdiFileImage"
        label="Choose a video or image to upload…"
        :previewHeight="375"
        @preview="focusTitle"
        :progressCallback="uploadHandler"
      ></MediaUpload>
      <v-card-text>
        <v-text-field
          ref="title"
          label="Title"
          name="title"
          :error-messages="titleErrors"
          v-model.trim="$v.title.$model"
          @input="$v.title.$touch()"
          @blur="$v.title.$touch()"
          size="255"
          required
        ></v-text-field>
        <div class="d-flex align-start mt-1">
          <v-icon color="#7070a0" left>{{ icon.mdiHelpCircle }}</v-icon>
          <p class="caption grey--text text--darken-2">Tags are a way for you
          to classify your post with words or phrases that customers might
          search for and follow or browse by. For example, they might describe
          something in the post, or a philosophy you work by e.g. "chocolate" or
          "eco-friendly".</p>
        </div>
        <v-combobox
          label="Tags"
          v-model="tags"
          chips
          small-chips
          clearable
          multiple
        >
          <template v-slot:selection="{ attrs, item, select, selected }">
            <v-chip
              v-bind="attrs"
              :input-value="selected"
              close
              small
              @click="select"
              @click:close="remove(item, tags)"
            >
              {{ item }}
            </v-chip>
          </template>
        </v-combobox>
        <TerritorySelect
          label="Publish in"
          v-model.trim="region"
          :custom-items="[{ value: 'allregions', text: 'All regions' }]"
        ></TerritorySelect>
      </v-card-text>
    </div>
    <v-card-text
      v-else
      class="text-center py-8"
    >
      <v-progress-linear
        color="#7070a0"
        height="20"
        :value="uploadValue"
        stream
        striped
        rounded
      ></v-progress-linear>
      <p class="pt-4">Uploading media&hellip;</p>
    </v-card-text>
    <v-card-actions>
      <div class="flex-grow-1"></div>
      <v-btn
        depressed
        dark
        color="#7070a0"
        :loading="loading"
        @click.stop="submit"
      >Continue</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import { validationMixin } from 'vuelidate'
  import { required } from 'vuelidate/lib/validators'
  import TagList from '@/aggroot/taglist'
  import TerritorySelect from '@/components/TerritorySelect'
  import MediaUpload from '@/components/MediaUpload'
  import { mdiFileImage, mdiHelpCircle } from '@mdi/js'

  export default {
    mixins: [validationMixin],
    components: {
      TerritorySelect,
      MediaUpload
    },
    data: function() {
      return {
        icon: {
          mdiFileImage,
          mdiHelpCircle
        },
        uploadValue: 0,
        preview: '',
        title: '',
        tags: new TagList(),
        region: null
      }
    },
    validations: {
      preview: {
        required
      },
      title: {
        required
      }
    },
    deactivated() {
      this.uploadValue = 0
      this.preview = ''
      this.title = ''
      this.tags = new TagList()
      this.region = null
    },
    computed: {
      ...mapGetters([
        'account'
      ]),
      titleErrors () {
        const errors = [],
              field = this.$v.title
        if (!field.$dirty) return errors
        !field.required && errors.push('Title is required')
        return errors
      },
      user () {
        return this.$store.getters.user
      },
      error() {
        return this.$store.getters.error
      },
      loading() {
        return this.$store.getters.loading
      }
    },
    watch: {
      account(value) {
        if (value && value.taxRegion) {
          this.region = value.taxRegion
        }
      }
    },
    methods: {
      ...mapActions([
        'setLoading',
        'createPost'
      ]),
      remove (tag, tags) {
        tags.splice(tags.indexOf(tag), 1)
        tags = new TagList(...tags)
      },
      submit() {
        this.$v.$touch()
        if (!this.$v.$invalid) {
          this.setLoading(true)
            .then(() => this.createPost({
              postTitle: this.title,
              postTags: this.tags,
              postRegion: this.region,
              uploadCallback: this.$refs.mediaUpload.upload
            }))
            .then(postID => {
              this.$v.$reset()
              this.setLoading(false)
              this.$router.push({
                name:'manage-products',
                params: { account: this.user.account, post_id: postID }
              }, () => {})
            })
        }
      },
      focusTitle(preview) {
        this.preview = preview
        this.$refs.title.focus()
      },
      uploadHandler(snapshot) {
        this.uploadValue = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      }
    }
  }
</script>