<template>
  <v-card>
    <v-toolbar class="page-title" flat dark color="#7070a0">
      <v-toolbar-title><h1 class="title">Edit address</h1></v-toolbar-title>
      <v-toolbar-title v-if="user" class="text-right">{{ user.account }}</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <AddressEdit ref="addressForm" v-model="address"/>
    </v-card-text>
    <v-card-actions>
      <v-btn
        text
        @click.stop="$router.push({name: 'account-addressbook', params: {'account': user.account}})"
      >Back to address book</v-btn>
      <div class="flex-grow-1"></div>
      <v-btn
        :loading="loading"
        @click.stop="submit"
        dark
        depressed
        color="#7070a0"
      >Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import AddressEdit from '@/components/AddressEdit'

  export default {

    name: 'AccountAddressEdit',

    components: {
      AddressEdit
    },

    data: () => ({
      address: null
    }),

    activated() {
      this.$store.dispatch('setLoading', false)
      if (this.routeUlid) {
        this.address = this.getAddress(this.routeUlid)
      }
    },

    deactivated() {
      this.address = null
    },

    computed: {
      routeUlid() {
        return this.$route.params.ulid
      },
      error() {
        return this.$store.getters.error
      },
      loading() {
        return this.$store.getters.loading
      }
    },

    methods: {
      getAddress(ulid) {
        return this.$store.getters.address.book.find(element => element.ulid === ulid);
      },
      submit() {
        const addressForm = this.$refs.addressForm
        addressForm.$touch()
        if (!addressForm.$invalid) {
          this.$store.dispatch('setLoading', true)

          let dispatchMethod = 'updateAddress'

          if (!this.address.ulid) {
            delete this.address.ulid
            dispatchMethod = 'createAddress'
          }

          this.$store.dispatch(dispatchMethod, this.address)
            .then(() => {
              addressForm.$reset()
              this.$store.dispatch('setLoading', false)
              this.$store.dispatch('triggerSnackbar', 'Saved successfully!')
              this.$router.push({name: 'account-addressbook', params: {'account': this.user.account}})
            })
        }
      }
    }
}
</script>
