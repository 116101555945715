<template>
  <v-card>
    <v-card-title>
      Change profile image
    </v-card-title>
    <v-alert v-if="error" type="error" class="ma-1">{{ error.message }}</v-alert>
    <v-card-text>
      <MediaUpload
        v-model="accountMedia"
        ref="mediaUpload"
        :icon="icon.mdiFileImage"
        label="Choose an image to upload…"
        @preview="accountMediaDirty = true"
        type="image"
        :previewWidth="200"
        :previewHeight="200"
        accept="image/*"
      ></MediaUpload>
    </v-card-text>
    <v-card-actions>
      <v-btn
        :to="{ name:'profile', params: { account: user.account }}"
        type="button"
        depressed
      >Cancel</v-btn>
      <div class="flex-grow-1"></div>
      <v-btn
        :loading="loading"
        dark
        depressed
        color="#7070a0"
        @click.stop="submit"
      >Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { validationMixin } from 'vuelidate'
  import { required } from 'vuelidate/lib/validators'
  import MediaUpload from '@/components/MediaUpload'
  import { getImageURL } from '@/image-cdn'
  import fb from '@/firebase'
  import { mdiFileImage } from '@mdi/js'

  export default {

    name: 'AccountImageEdit',

    mixins: [validationMixin],

    components: {
      MediaUpload
    },

    data() {
      return {
        icon: { mdiFileImage },
        accountMediaDirty: false,
        accountMedia: null
      }
    },

    validations: {
      accountMedia: {
        required
      }
    },

    computed: {
      ...mapGetters([
        'loading',
        'error'
      ])
    },

    mounted() {
      this.$store.dispatch('getAccount', this.$route.params.account)
        .then(account => {
          const media = account.media

          if (media) {
            this.accountMedia = getImageURL(media, 200, 200, account.updated || account.created)
          }
        })
    },

    methods: {
      submit() {
        this.$store.dispatch('clearError')
        this.$v.$touch()
        if (this.$v.$invalid) {
          this.$store.dispatch('setError', 'Image is required.')
        } else {
          if (this.accountMediaDirty) {
            this.$store.dispatch('setLoading', true)
            const mediaUrl = `profile/${ this.user.account }`
            this.$refs.mediaUpload.upload(mediaUrl)
            this.$refs.mediaUpload.$once('upload-complete', () => {
              fb.db.collection('accounts').doc(this.user.account)
                .update({
                  'updated': new Date().getTime(),
                  'media': mediaUrl
                })
                .then(() => {
                  this.$v.$reset()
                  this.$store.dispatch('setLoading', false)
                  this.$router.push({ name:'profile', params: { account: this.user.account }})
                })
            })
          }
        }
      }
    }
  }
</script>

<style lang="scss">
</style>
