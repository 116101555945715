<template>
  <div v-if="account">
    <v-card>
      <form method="post" @submit.prevent="submit">
        <v-toolbar class="page-title" flat dark color="#7070a0">
          <v-toolbar-title><h1 class="title">Account settings</h1></v-toolbar-title>
          <v-toolbar-title v-if="user" class="text-right">{{ user.account }}</v-toolbar-title>
        </v-toolbar>
        <v-card-title>
          <h2 class="title">Account identification</h2>
        </v-card-title>
        <v-card-text>
          <v-text-field
            label="Business/individual legal name"
            :error-messages="requiredError($v.legalName, 'Legal name')"
            v-model.trim="$v.account.legalName.$model"
            @input="$v.account.legalName.$touch()"
            @blur="$v.account.legalName.$touch()"
            size="255"
            hint="Must be full legal name of entity that owns the account. For example, “My Company Ltd” for a limited business or “Joseph Bloggs” for an individual or sole trader."
            persistent-hint
          ></v-text-field>
          <v-text-field
              label="Business email"
              autocomplete="email"
              :error-messages="emailErrors($v.account.email)"
              v-model.trim="$v.account.email.$model"
              hint="Business email address to expose to customers"
              persistent-hint
              id="email"
              @keyup="lower"
              @input="$v.account.email.$touch()"
              @blur="$v.account.email.$touch()"
              type="email"
              size="255"
            ></v-text-field>
          <v-row align="center">
            <v-col cols="6">
              <TerritorySelect
                label="Tax region"
                v-model.trim="$v.account.taxRegion.$model"
                @input="$v.account.taxRegion.$touch()"
                @blur="$v.account.taxRegion.$touch()"
                :error-messages="requiredError($v.account.taxRegion, 'Tax region')"
              ></TerritorySelect>
            </v-col>
            <v-col cols="6">
              <CurrencySelect
                label="Default currency"
                v-model.trim="$v.account.currency.$model"
                @input="$v.account.currency.$touch()"
                @blur="$v.account.currency.$touch()"
                :error-messages="requiredError($v.account.currency, 'Default currency')"
              ></CurrencySelect>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-title>
          <h2 class="title">Account type</h2>
        </v-card-title>
        <v-card-text>
          <p>How will you be using Nuokka?</p>
          <v-radio-group v-model="account.type">
            <v-radio color="#7070a0" value="seller">
              <template v-slot:label>
                <span>I'm a merchant; I want to <strong>sell products/services</strong></span>
              </template>
            </v-radio>
            <v-radio color="#7070a0" value="buyer">
              <template v-slot:label>
                <span>I'm a customer; I only want to <strong>browse and purchase</strong></span>
              </template>
            </v-radio>
          </v-radio-group>
        </v-card-text>
        <div v-if="account.type === 'seller'">
          <StripeNotice>
            We use Stripe to make sure you get paid on time and to keep
            your business payment details secure. Merchant accounts need to be
            linked to an active Stripe account, which you can update at any time
            below.

            <v-row align="center" justify="center">
              <v-col cols="6" class="text-center">
                <strong v-if="account.stripeUserID">You are connected to</strong>
                <strong v-else>You must connect to</strong>
                <v-img src="/img/stripe-grey.svg" height="32" contain />
              </v-col>
              <v-col cols="6">
                <v-btn
                  block
                  type="button"
                  color="primary"
                  class="d-inline-block"
                  id="connect-to-stripe"
                  @click="connectToStripe"
                >
                  <span class="text-wrap" v-if="account.stripeUserID">Update Stripe account</span>
                  <span class="text-wrap" v-else>Connect Stripe account</span>
                </v-btn>
              </v-col>
            </v-row>
          </StripeNotice>
          <v-card-title>
            <h2 class="title">Business Profile</h2>
          </v-card-title>
          <v-card-text>
            <IndustrySelect
              label="Business industry"
              v-model.trim="$v.account.industry.$model"
              @input="$v.account.industry.$touch()"
              @blur="$v.account.industry.$touch()"
              :error-messages="requiredError($v.account.industry, 'Business industry')"
            ></IndustrySelect>
            <v-textarea
              label="About your business"
              v-model="account.about"
              hint="Provide a paragraph or two about your and your business, as
              an introduction to customers. This appears at the top of your
              profile."
              persistent-hint
              filled
            ></v-textarea>
            <div class="d-flex align-start mt-1">
              <v-icon color="#7070a0" left>{{ icon.mdiHelpCircle }}</v-icon>
              <p class="caption grey--text text--darken-2">Tags are a way for you
              to classify your business with words or phrases that customers might
              search for and follow or browse by. For example, they might describe
              what you sell, or a philosophy you work by e.g. "chocolate" or
              "eco-friendly".</p>
            </div>
            <v-combobox
              label="Tags"
              autocomplete="off"
              v-model="account.tags"
              chips
              small-chips
              clearable
              multiple
              hint="Provide some tags that classify your business for search. Type ⇥ (Tab) or ⏎ (Return) to separate."
              persistent-hint
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  small
                  @click="select"
                  @click:close="remove(item, account.tags)"
                >
                  {{ item }}
                </v-chip>
              </template>
            </v-combobox>
          </v-card-text>
          <v-card-title>
            <h2 class="title">Shipping</h2>
          </v-card-title>
          <v-card-text>
            <v-select
              label="Collection"
              :items="[{
                text: 'Available for all products',
                value: 'available-all'
              }, {
                text: 'Available for some products',
                value: 'available-some'
              }, {
                text: 'Unavailable',
                value: 'unavailable'
              }]"
              v-model.trim="$v.account.collection.$model"
              @input="$v.account.collection.$touch()"
              @blur="$v.account.collection.$touch()"
              :error-messages="requiredError($v.account.collection, 'Collection option')"
            ></v-select>
            <v-select
              label="Shipping"
              :items="[{
                text: 'Free',
                value: 'free'
              }, {
                text: 'Flat rates',
                value: 'flat'
              }, {
                text: 'Price-based rates',
                value: 'price'
              }, {
                text: 'Product specific',
                value: 'product'
              }, {
                text: 'Unavailable',
                value: 'unavailable'
              }]"
              v-model.trim="$v.account.shipping.$model"
              @input="changeShipping()"
              @blur="$v.account.shipping.$touch()"
              :error-messages="requiredError($v.account.shipping, 'Shipping option')"
              hint="Be careful! Changing this option will reset and lose any shipping rates already set."
              persistent-hint
            ></v-select>
            <v-select
              v-if="$v.account.shipping.$model !== 'unavailable'"
              label="International Shipping"
              :items="[{
                text: 'Covered by standard shipping',
                value: 'standard'
              }, {
                text: 'By arrangement',
                value: 'arrangement'
              }, {
                text: 'Unavailable',
                value: 'unavailable'
              }]"
              v-model.trim="$v.account.intShipping.$model"
              @input="$v.account.intShipping.$touch()"
              @blur="$v.account.intShipping.$touch()"
              :error-messages="requiredError($v.account.intShipping, 'International shipping option')"
            ></v-select>
          </v-card-text>
          <div v-if="showRates">
            <v-card-title>
              <h2 class="title">Shipping Rates</h2>
            </v-card-title>
            <div class="d-flex align-start px-4">
              <v-icon color="#7070a0" left>{{ icon.mdiHelpCircle }}</v-icon>
              <p class="caption grey--text text--darken-2">Since you selected
              either fixed or price-based shipping rates, you will now need to
              define at least one shipping rate.</p>
            </div>
            <v-card-actions>
              <div class="flex-grow-1"></div>
              <v-btn
                icon
                small
                @click.stop="addRate"
                type="button"
              >
                <v-icon>{{ icon.mdiPlus }}</v-icon>
              </v-btn>
              <v-chip
                class="ma-2"
                color="green"
                text-color="white"
              >{{ account.shippingRates.length }} rate(s)</v-chip>
              <v-btn
                icon
                small
                @click.stop="removeRate"
                type="button"
                :disabled="account.shippingRates.length <= 1"
              >
                <v-icon>{{ icon.mdiMinus }}</v-icon>
              </v-btn>
              <div class="flex-grow-1"></div>
            </v-card-actions>
            <v-card-text
              v-for="(v, index) in $v.account.shippingRates.$each.$iter"
              :key="'shippingrate' + index"
            >
              <div v-if="account.shipping === 'flat'">
                <v-text-field
                  label="Shipping rate title"
                  v-model.trim="v.title.$model"
                  @input="v.title.$touch()"
                  @blur="v.title.$touch()"
                  :error-messages="requiredError(v.title, 'Rate title')"
                  hint="e.g. 'Standard Shipping' or 'Expedited Shipping'"
                  persistent-hint
                ></v-text-field>
              </div>
              <v-row v-if="account.shipping === 'price'">
                <v-col class="py-0" cols="6">
                  <v-text-field
                    label="Order minimum"
                    class="text-right"
                    v-model.trim="v.minimum.$model"
                    @input="v.minimum.$touch()"
                    @blur="v.minimum.$touch()"
                    :error-messages="minMaxErrors(v.minimum, 'Minimum')"
                  ></v-text-field>
                </v-col>
                <v-col class="py-0" cols="6">
                  <v-text-field
                    label="Order maximum"
                    class="text-right"
                    v-model.trim="v.maximum.$model"
                    @input="v.maximum.$touch()"
                    @blur="v.maximum.$touch()"
                    :error-messages="minMaxErrors(v.maximum, 'Maximum')"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0" cols="6">
                  <CurrencySelect
                    label="Currency"
                    v-model.trim="v.currency.$model"
                    @input="v.currency.$touch()"
                    @blur="v.currency.$touch()"
                    :error-messages="requiredError(v.currency, 'Currency')"
                  ></CurrencySelect>
                </v-col>
                <v-col class="py-0" cols="6">
                  <v-text-field
                    label="Shipping Price"
                    class="text-right"
                    v-model.trim="v.price.$model"
                    @input="v.price.$touch()"
                    @blur="v.price.$touch()"
                    :error-messages="priceErrors(v.price)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </div>
        </div>
        <v-card-actions>
          <v-btn
            :loading="loading"
            class="flex-grow-1"
            color="#7070a0"
            type="submit"
            depressed
            dark
          >Save</v-btn>
        </v-card-actions>
      </form>
    </v-card>
    <v-card class="mt-4">
      <form method="post" @submit.prevent="deleteAccount">
        <v-card-title>
          <h2 class="title red--text">Delete account</h2>
        </v-card-title>
        <v-card-text>
          <p class="body-2">If you confirm deleting your account, we will not be able to recover it.</p>
          <v-text-field
            id="accountdeletepassword"
            name="accountdeletepassword"
            autocomplete="new-password"
            label="Confirm current password"
            v-model.trim="currentPassword"
            :error-messages="reauthError"
            :append-icon="showPass ? icon.mdiEye : icon.mdiEyeOff"
            :type="showPass ? 'text' : 'password'"
            @click:append="showPass2 = !showPass2"
            size="255"
            dense
          ></v-text-field>
          <div class="text-right">
            <v-btn
              small
              depressed
              color="error"
              type="submit"
            >
              <v-icon left>{{ icon.mdiFire }}</v-icon>
              Delete account
            </v-btn>
          </div>
        </v-card-text>
      </form>
    </v-card>
    <v-dialog
      v-model="confirm.active"
      max-width="400"
    >
      <v-card>
        <v-card-title class="headline">Are you sure?</v-card-title>

        <v-card-text>
          Do you really want to delete the account "{{ routeAccount }}"?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="resetConfirm">No</v-btn>
          <v-btn text @click.stop="confirm.onConfirm">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import TagList from '@/aggroot/taglist'
  import CurrencySelect from '@/components/CurrencySelect'
  import TerritorySelect from '@/components/TerritorySelect'
  import IndustrySelect from '@/components/IndustrySelect'
  import StripeNotice from '@/components/StripeNotice'
  import { validationMixin } from 'vuelidate'
  import {
    decimal,
    email,
    required,
    requiredIf
  } from 'vuelidate/lib/validators'
  import {
    mdiHelpCircle,
    mdiFire,
    mdiPlus,
    mdiMinus
  } from '@mdi/js'

  export default {
    mixins: [ validationMixin ],

    components: {
      CurrencySelect,
      TerritorySelect,
      IndustrySelect,
      StripeNotice
    },

    name: 'AccountEdit',

    data: () => ({
      icon: {
        mdiHelpCircle,
        mdiFire,
        mdiPlus,
        mdiMinus
      },
      account: {
        legalName: '',
        taxRegion: null,
        currency: null,
        industry: '',
        email: '',
        about: '',
        tags: [],
        type: 'buyer',
        stripeUserID: '',
        collection: 'unavailable',
        shipping: 'unavailable',
        intShipping: 'unavailable',
        shippingRates: []
      },
      confirm: {
        active: false,
        description: "",
        onConfirm: null
      },
      showPass: false,
      currentPassword: '',
      reauthError: ''
    }),

    validations() {
      const validations = {
        account: {
          legalName: { required },
          taxRegion: { required },
          currency: { required },
          industry: { required },
          email: {
            required,
            email
          },
          type: { required },
          collection: { required },
          shipping: { required },
          intShipping: {
            required: requiredIf(() => this.account.shipping !== 'unavailable')
          },
          shippingRates: {
            $each: {
              currency: { required },
              price: { required, decimal }
            }
          }
        }
      }

      if (this.account.shipping === 'flat') {
        validations.account.shippingRates.$each.title = { required }
      }

      if (this.account.shipping === 'price') {
        validations.account.shippingRates.$each.minimum = { required, decimal }
        validations.account.shippingRates.$each.maximum = { required, decimal }
      }

      return validations
    },

    activated() {
      if (this.user && this.user.account) {
        if (this.routeAccount !== this.user.account) {
          // Bit of extra security
          this.$router.push({...this.$route, params: {account: this.user.account}})
        }

        this.$store.dispatch('getAccount', this.routeAccount)
          .then(account => {
            this.account = account
            this.account.oldTags = [...(this.account.tags || [])]
          })
          .catch(error => this.logError({ error }))
      } else {
        this.$store.dispatch('setAppLoading', true)
      }
    },

    computed: {
      ...mapGetters([
        'currencies'
      ]),
      error () {
        return this.$store.getters.error
      },
      loading () {
        return this.$store.getters.loading
      },
      routeAccount() {
        return this.$route.params.account
      },
      showRates() {
        return Boolean(
          this.account.shipping === "flat" ||
          this.account.shipping === "price"
        )
      },
      rateTemplate() {
        return {
          type: this.account.shipping,
          currency: this.account.currency,
          price: null,
          title: "",
          minimum: null,
          maximum: null
        }
      }
    },

    methods: {
      deleteAccount() {
        const self = this

        this.confirm = {
          active: true,
          onConfirm: () => {
            self.resetConfirm()
            if (self.currentPassword) {
              self.$store.dispatch('deleteAccount', {
                account: self.routeAccount,
                email: self.user.email,
                password: self.currentPassword
              })
            } else {
              self.reauthError = "Password required for account deletion."
            }
          }
        }
      },
      resetConfirm() {
        this.confirm = {
          active: false,
          description: "",
          onConfirm: null
        }
      },
      addRate() {
        const sr = this.account.shippingRates
        sr.push({ ...this.rateTemplate })
      },
      removeRate() {
        const sr = this.account.shippingRates
        if(sr.length > 1) sr.pop()
      },
      remove(tag, tags) {
        tags.splice(tags.indexOf(tag), 1)
        tags = new TagList(...tags)
      },
      getFQUri(path) {
        const loc = window.location
        return loc.protocol + '//' + loc.host + path
      },
      connectToStripe() {
        this.$store.commit('setStateToken')
        const redirectUri = this.getFQUri('/stripe/connect/edit')
        window.location.href = `https://connect.stripe.com/oauth/authorize?response_type=code&scope=read_write&redirect_uri=${redirectUri}&client_id=${this.$config.stripe.clientId}&state=${this.$store.getters.stateToken}`
      },
      changeType() {
        if (this.account.type === 'seller' && !this.account.stripeUserID) {
          this.connectToStripe()
        }
      },
      changeShipping() {
        this.$v.account.shipping.$touch()
        this.$v.account.shippingRates.$reset()
        this.$set(this.account, "shippingRates", this.showRates ? [{ ...this.rateTemplate }] : [])
      },
      lower(field) {
        field.$model = field.$model && field.$model.toLowerCase()
      },
      requiredError(field, label) {
        return field && field.$dirty && !field.required ? label + ' is required' : ''
      },
      emailErrors (field) {
        const errors = []
        if (!field.$dirty) return errors
        !field.required && errors.push('Email is required')
        !field.email && errors.push('Must be a valid email address')
        return errors
      },
      priceErrors(field) {
        const errors = []
        if (!field.$dirty) return errors
        !field.required && errors.push('Price is required')
        !field.decimal && errors.push('Price must be a decimal value')
        return errors
      },
      minMaxErrors(field, label) {
        const errors = []
        if (!field.$dirty) return errors
        !field.required && errors.push(label + ' is required')
        !field.decimal && errors.push(label + ' must be a decimal value')
        return errors
      },
      submit() {
        this.$v.$touch()
        if (!this.$v.$invalid) {
          this.account.tags = new TagList(this.account.tags)
          this.$store.dispatch('clearError')
          this.$store.dispatch('setLoading', true)
          this.$store.dispatch('updateAccount', this.account)
            .then(() => {
              this.account.oldTags = [...this.account.tags]
              this.$store.dispatch('setLoading', false)
              this.$store.dispatch('triggerSnackbar', 'Saved successfully!')
            })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~vuetify/src/styles/settings/_variables';

  #connect-to-stripe {
    height: auto;
    padding: 4px;
  }

  .v-alert.stripe-notice {
    background: url(/img/stripe-grey.svg) 16px center no-repeat !important;
    background-size: 80px auto !important;
    padding: 16px 16px 16px 112px;
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    .v-alert.stripe-notice {
      background: url(/img/stripe-grey.svg) center 16px no-repeat !important;
      background-size: 80px auto !important;
      padding: 60px 16px 16px 16px;
    }
  }
</style>
