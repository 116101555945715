<template>
  <div></div>
</template>

<script>
  import fb from '@/firebase'

  const stripeConnect = fb.func.httpsCallable('stripeConnect')

  export default {

    name: 'AccountStripeConnect',

    data: () => ({}),

    activated() {
      this.$store.dispatch('setAppLoading', true)

      if (this.user && this.user.account) {
        const { code } = this.$route.query
        const isEdit = this.$route.path.includes('edit')

        if (code) {
          const redirectRoute = isEdit ?
            {name: 'account-edit', params: {account: this.user.account}} :
            {name: 'stream'}

          this.logInfo('Calling stripeConnect')
          stripeConnect({'code': code})
            .then(result => {
              const stripeUserID = result.data.stripe_user_id

              return this.$store.dispatch('connectAccountToStripeID', stripeUserID)
            })
            .then(stripeUserID => {
              this.logInfo(`Connected ${this.user.account} to ${stripeUserID}`)
              this.$store.dispatch('setAppLoading', false)
              this.$router.push(redirectRoute, () => {})
            })
            .catch(error => {
              this.logError('Error connecting to Stripe', error)
            })
        }
      }
    }
  }
</script>
