<template>
  <v-card>
    <v-toolbar class="page-title" flat dark color="#7070a0">
      <v-toolbar-title><h1 class="title">Shopping cart</h1></v-toolbar-title>
    </v-toolbar>

    <v-card-text
      v-if="!cart || !cart.items.length"
      class="text-center subtitle-1 my-4"
    >
      Your cart is currently empty.
    </v-card-text>

    <v-list v-else>
      <template v-for="(item, index) in cart.items">
        <v-list-item
          v-if="item.account in merchants && merchants[item.account].status === accountStatus.DEMO"
          class="pa-1 pt-0"
          :key="'itemlabel'+index"
        >
          <v-alert
            dense
            text
            color="#6F8FAE"
            class="flex-grow-1 text-center caption pa-1 ma-0">
            Demo merchant: This product will not checkout
          </v-alert>
        </v-list-item>
        <v-list-item
          v-if="item.account in merchants && merchants[item.account].status === accountStatus.DISABLED"
          class="pa-1 pt-0"
          :key="'itemlabel'+index"
        >
          <v-alert
            dense
            text
            color="red"
            class="flex-grow-1 text-center caption pa-1 ma-0">
            Merchant deactivated: This product will not checkout
          </v-alert>
        </v-list-item>
        <v-list-item
          v-if="item.account in merchants && !merchants[item.account].isStripeConnected"
          class="pa-1 pt-0"
          :key="'itemlabel'+index"
        >
          <v-alert
            dense
            text
            color="orange"
            class="flex-grow-1 text-center caption pa-1 ma-0">
            Merchant has not connected to Stripe:<br>This product will not checkout
          </v-alert>
        </v-list-item>
        <v-list-item
          :key="'itemtop'+index"
          class="justify-end"
        >
          <v-list-item-avatar tile size="40" class="my-1 mr-4">
            <v-img :src="item.mediaSrc"></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="body-2 font-weight-bold">{{ item.title }}</v-list-item-title>
            <v-list-item-subtitle v-if="optionsWithoutQuantity(item)" class="caption">
              {{ optionsOutput(item) }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content class="justify-end vlic-stock caption" v-if="products[item.id] && products[item.id].deriveStock(optionsWithoutQuantity(item))">
            Stock: {{ products[item.id].deriveStock(optionsWithoutQuantity(item)) }}
          </v-list-item-content>
          <v-list-item-content class="justify-end vlic-stock caption" v-else>
            <strong>Out of stock</strong>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          :key="'itembottom'+index"
        >
          <v-list-item-content class="align-self-start justify-start">
            <v-text-field
              label="Qty"
              ref="quantity"
              v-model="item.options.quantity"
              :error="errorState(index)"
              @blur="updateItem(index)"
              class="caption text-right"
              dense
              outlined
            ></v-text-field>
          </v-list-item-content>

          <v-list-item-action class="align-self-start py-1">
            <v-btn text small class="red--text" @click.stop="confirmRemove(index, item.title)">
              <v-icon left>{{ icon.mdiTrashCanOutline }}</v-icon>
              Remove
            </v-btn>
          </v-list-item-action>

          <v-list-item-content class="align-self-start justify-end caption py-5">
            {{ getPrice(item) }}
          </v-list-item-content>
        </v-list-item>
        <v-divider
          v-if="index + 1 < cart.items.length"
          :key="'divider' + index"
        ></v-divider>
      </template>
    </v-list>
    <v-card-actions>
      <v-btn
        text
        @click="$router.go(-1)"
      >Back</v-btn>
      <div class="flex-grow-1"></div>
      <v-btn
        dark
        depressed
        color="#7070a0"
        :disabled="!cart || !cart.items || cart.items.length < 1"
        :loading="loading"
        @click.stop="submit"
      >Go to checkout</v-btn>
    </v-card-actions>
    <v-dialog
      v-model="confirm.active"
      max-width="400"
    >
      <v-card>
        <v-card-title class="headline">Remove Item?</v-card-title>

        <v-card-text>
          Are you sure you want to remove "<span class="font-weight-bold">{{ confirm.description }}</span>" from your cart?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="resetConfirm">No</v-btn>
          <v-btn text @click.stop="confirm.onConfirm">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
  import fb from '@/firebase'
  import Product from '@/aggroot/product'
  import { mdiTrashCanOutline } from '@mdi/js'
  import { accountStatus } from '@/enums'

  export default {
    data () {
      return {
        icon: { mdiTrashCanOutline },
        accountStatus,
        confirm: {
          active: false,
          description: "",
          onConfirm: null
        },
        products: {},
        merchants: {}
      }
    },
    computed: {
      cart() {
        return this.$store.getters.cart
      },
      account() {
        return this.$store.getters.account
      },
      loading() {
        return this.$store.getters.loading
      }
    },
    activated() {
      fb.analytics.logEvent('view_cart')
    },
    deactivated() {
      this.$store.dispatch('setLoading', false)
      this.$store.dispatch('clearError')
    },
    beforeDestroy() {
      this.products = {}
    },
    watch: {
      cart: {
        immediate: true,
        handler: function(val) {
          if (val && val.items && val.items.length) {
            val.items.forEach(
              item => {
                this.$set(this.products, item.id, Product.fromObj(item))

                if (!(item.account in this.merchants)) {
                  this.$store.dispatch('getAccount', item.account)
                    .then(account =>
                      this.$set(this.merchants, item.account, account)
                    )
                }
              }
            )
          }
        }
      }
    },
    methods: {
      getPrice(item) {
        const quantity = isNaN(item.options.quantity) ? 0 : item.options.quantity
        const linePrice = quantity * item.unitPrice
        return new Intl.NumberFormat(
          navigator.language || 'en', {
            style: 'currency',
            currency: item.currency
          }).format(linePrice)
      },
      errorState(index) {
        if (
          !this.$refs ||
          !this.$refs.quantity ||
          !this.$refs.quantity[index]
        ) { return false }

        const value = this.$refs.quantity[index].value
        const item = this.cart.items[index]

        return (
          !value ||
          isNaN(value) ||
          value > this.products[item.id].deriveStock(
            this.optionsWithoutQuantity(item)
          )
        )
      },
      updateItem(index) {
        if (!this.errorState(index)) {
          this.$store.dispatch('updateCartItem', {
            index,
            quantity: this.$refs.quantity[index].value
          })
        }
      },
      isValid() {
        return this.$refs.quantity.every(
          (item, index) => !this.errorState(index)
        )
      },
      submit() {
        if (this.isValid()) {
          this.$router.push({name: 'checkout'}, () => {})
        } else {
          this.logInfo('Form failed validation.')
        }
      },
      optionsOutput(item) {
        const options = Object.entries(this.optionsWithoutQuantity(item))

        return options.reduce((acc, currentValue, index, array) => {
          acc += currentValue[1]
          acc += index < array.length - 1 ? ', ' : ''

          return acc
        }, "")
      },
      optionsWithoutQuantity(item) {
        const options = { ...item.options }
        delete options['quantity']
        return options
      },
      resetConfirm() {
        this.confirm = {
          active: false,
          description: "",
          onConfirm: null
        }
      },
      confirmRemove(index, description) {
        const self = this

        this.confirm = {
          active: true,
          description: description,
          onConfirm: () => {
            self.$store.dispatch('removeCartItem', index)
            self.resetConfirm()
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .v-list-item {
    min-height: 16px;
  }
  .vlic-data {
    flex-basis: 70%;
  }
  .vlic-stock {
    text-align: right;
  }
</style>
