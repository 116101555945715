<template>
  <v-card v-if="componentLoaded">
    <div v-if="!serverLoading">
      <v-toolbar class="page-title" flat dark color="#7070a0">
        <v-toolbar-title><h1 class="title">Checkout</h1></v-toolbar-title>
      </v-toolbar>
      <v-alert v-if="error" type="error" class="ma-1">{{ error.message }}</v-alert>
      <template v-if="!hasOrders">
        <v-card-text>
          There are no items in your cart that can currently be processed
          through checkout.
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            @click.stop="$router.push({name: 'cart'}, () => {})"
          >
            <v-icon left>{{ icon.mdiArrowLeft }}</v-icon>
            Back
          </v-btn>
        </v-card-actions>
      </template>
      <template v-else>
        <v-stepper
          v-model="step"
          vertical
        >
          <v-stepper-step
            color="#7070a0"
            step="1"
          >
            <h2 class="body-1">Your details</h2>
          </v-stepper-step>

          <v-stepper-content
            step="1"
          >
            <p>Name: <strong>{{ this.info.name }}</strong></p>
            <p>Email: <strong>{{ this.info.email }}</strong></p>
            <AddressBookSelect
              label="Billing address"
              required
              v-model="$v.info.billingAddressUlid.$model"
              @input="$v.info.billingAddressUlid.$touch()"
              @blur="$v.info.billingAddressUlid.$touch()"
              :error-messages="errorRequired($v.info.billingAddressUlid, 'Billing address')"
              class="mb-6"
            ></AddressBookSelect>
            <AddressBookSelect
              label="Shipping address"
              required
              help
              v-model="$v.info.shippingAddressUlid.$model"
              @input="$v.info.shippingAddressUlid.$touch()"
              @blur="$v.info.shippingAddressUlid.$touch()"
              :error-messages="errorRequired($v.info.shippingAddressUlid, 'Shipping address')"
              class="mb-6"
            ></AddressBookSelect>
            <v-card-actions>
              <div class="flex-grow-1"></div>
              <v-btn
                text
                @click.stop="$router.push({name: 'cart'}, () => {})"
              >
                <v-icon left>{{ icon.mdiArrowLeft }}</v-icon>
                Back
              </v-btn>
              <v-btn
                dark
                depressed
                color="#7070a0"
                @click.stop="submitStep1"
                :loading="loading"
              >Continue</v-btn>
            </v-card-actions>
          </v-stepper-content>

          <v-stepper-step
            color="#7070a0"
            step="2"
          >
            <h2 class="body-1">Payment details</h2>
          </v-stepper-step>

          <v-stepper-content
            step="2"
            class="pl-0"
          >
            <v-card-text>
              <v-text-field
                label="Card holder name"
                v-model="$v.payment.cardHolder.$model"
                @input="$v.payment.cardHolder.$touch()"
                @blur="$v.payment.cardHolder.$touch()"
                :error-messages="errorRequired($v.payment.cardHolder, 'Card holder name')"
              ></v-text-field>
              <div id="card-element"></div>
              <v-alert
                color="grey darken-1"
                class="stripe-notice body-2"
                outlined
              >
                We use Stripe to provide safe and secure payments to merchants.
                This also means we do not store any of your card details.
              </v-alert>
            </v-card-text>
            <v-card-actions>
              <div class="flex-grow-1"></div>
              <v-btn
                text
                @click.stop="step = 1"
              >
                <v-icon left>{{ icon.mdiArrowLeft }}</v-icon>
                Back
              </v-btn>
              <v-btn
                color="#7070a0"
                dark
                depressed
                :loading="loading"
                @click.stop="submitStep2"
              >Continue</v-btn>
            </v-card-actions>
          </v-stepper-content>

          <v-stepper-step
            color="#7070a0"
            step="3"
          >
            <h2 class="body-1">Review your order</h2>
          </v-stepper-step>

          <v-stepper-content
            step="3"
            class="pl-0 pr-12 pb-0"
          >
            <div
              v-for="(merchant, account) in orders"
              :key="account"
            >
              <v-toolbar flat dark color="#7070a0">
                <v-toolbar-title>{{ merchant.merchant ? merchant.merchant.legalName : '' }}</v-toolbar-title>
                <div class="flex-grow-1"></div>
                {{ merchant.merchant ? formatPrice(merchant.merchant.currency, getMerchantTotal(merchant)) : '' }}
              </v-toolbar>
              <v-row
                v-if="
                  isMerchantInternational(merchant.merchant)
                  && (
                    !merchant.merchant.intShipping
                    || merchant.merchant.intShipping === 'unavailable'
                  )
                "
                class="px-4 py-2" no-gutters
              >
                <v-col
                  class="red--text"
                >
                  International shipping is <strong>not available</strong> from this merchant!<br/>
                  Please remove the following items.
                </v-col>
              </v-row>
              <v-list>
                <template v-for="(item, index) in merchant.items">
                  <div :key="'item'+index">
                    <v-list-item
                      class="justify-end"
                    >
                      <v-list-item-avatar tile size="40" class="mr-4">
                        <v-img :src="item.mediaSrc"></v-img>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title class="body-2 font-weight-bold">{{ item.title }}</v-list-item-title>
                        <v-list-item-subtitle v-if="item.options" class="caption">
                          {{ optionsOutput(item) }}
                        </v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-content class="justify-end vlic-stock caption" v-if="products[item.productID] && products[item.productID].deriveStock(item.options)">
                        Stock: {{ products[item.productID].deriveStock(item.options) }}
                      </v-list-item-content>
                      <v-list-item-content class="justify-end vlic-stock caption" v-else>
                        <strong>Out of stock</strong>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content class="flex-grow-1 align-self-start justify-start pb-0">
                        <v-text-field
                          label="Qty"
                          class="caption text-right"
                          dense
                          outlined
                          hide-details
                          :ref="`${account}-qty`"
                          v-model="item.quantity"
                          :error="errorState(account, index)"
                          @blur="$refs[`${account}-qty`] ? updateItem(account, item.cartIndex, $refs[`${account}-qty`][index].value) : false"
                        ></v-text-field>
                      </v-list-item-content>

                      <v-list-item-action class="align-self-start">
                        <v-btn icon small class="mx-2 mt-2 red--text" @click.stop="confirmRemove(index, account, item.title)">
                          <v-icon>{{ icon.mdiTrashCanOutline }}</v-icon>
                        </v-btn>
                      </v-list-item-action>

                      <v-list-item-content class="vlic-total align-self-start justify-end caption pt-6 pb-0">
                        {{ item.formatPrice(item.itemTotal) }}
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-if="isStandardShipping(item, merchant.merchant)"
                    >
                      <v-list-item-content class="flex-grow-1 align-self-start justify-start">
                        <v-select
                          label="Collect or deliver"
                          dense
                          outlined
                          hide-details
                          v-model="item.shippingOption"
                          :items="getShippingOptions(item)"
                        ></v-select>
                      </v-list-item-content>

                      <v-list-item-content class="vlic-total align-self-start justify-end caption pt-6">
                        {{ item.shippingCost !== false ? item.formatPrice(item.shippingCost) : '' }}
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                  </div>
                </template>
              </v-list>
              <v-row
                v-if="!isMerchantInternational(merchant.merchant) || merchant.merchant.intShipping === 'standard'"
                class="px-4 py-2" no-gutters
              >
                <v-col class="vlic-shipping">
                  <v-select
                    v-if="merchant.merchant.shipping === 'flat' &&
                      merchant.merchant.shippingRates &&
                      merchant.merchant.shippingRates.length"
                    label="Shipping rate"
                    dense
                    outlined
                    hide-details
                    v-model="merchant.merchant.shippingOption"
                    :items="getMerchantShippingOptions(merchant.merchant.shippingRates)"
                  ></v-select>
                </v-col>
                <v-col class="vlic-total caption text-right pt-3">
                  {{ merchant.merchant ? formatPrice(merchant.merchant.currency, getMerchantShippingTotal(merchant)) : '' }}
                </v-col>
              </v-row>
              <v-row
                v-else-if="isMerchantInternational(merchant.merchant) && merchant.merchant.intShipping === 'arrangement'"
                class="px-4 py-2" no-gutters
              >
                <v-col class="vlic-shipping caption">
                  International delivery is by arrangement from this merchant. You
                  will not be charged now, but should expect to be contacted later
                  and charged once arranged.
                </v-col>
                <v-col class="vlic-total caption text-right pt-3">
                  {{ merchant.merchant ? formatPrice(merchant.merchant.currency, 0) : '' }}
                </v-col>
              </v-row>
            </div>
            <v-card-text class="d-flex headline align-start">
              <p>Total:</p>
              <div class="flex-grow-1"></div>
              <p class="text-right" v-html="getTotalsOutput()"></p>
            </v-card-text>
            <v-card-actions>
              <div class="flex-grow-1"></div>
              <v-btn
                text
                @click.stop="step = 2"
              >
                <v-icon left>{{ icon.mdiArrowLeft }}</v-icon>
                Back
              </v-btn>
              <v-btn
                :loading="loading"
                @click.stop="submitStep3"
                color="#7070a0"
                dark
                depressed
              >Place order</v-btn>
            </v-card-actions>
          </v-stepper-content>
        </v-stepper>
      </template>
      <v-dialog
        v-model="confirm.active"
        max-width="400"
      >
        <v-card>
          <v-card-title class="headline">Remove Item?</v-card-title>

          <v-card-text>
            Are you sure you want to remove "<span class="font-weight-bold">{{ confirm.description }}</span>" from your cart?
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn text @click="resetConfirm">No</v-btn>
            <v-btn text @click.stop="confirm.onConfirm">Yes</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <PleaseWait v-else>
      Processing, please wait…
    </PleaseWait>
  </v-card>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import { validationMixin } from 'vuelidate'
  import { required } from 'vuelidate/lib/validators'
  import fb from '@/firebase'
  import { accountStatus } from '@/enums'
  import Product from '@/aggroot/product'
  import MerchantOrderItem from '@/aggroot/merchantOrderItem'
  import PleaseWait from '@/components/app/PleaseWait'
  import AddressBookSelect from '@/components/AddressBookSelect'
  import { mdiTrashCanOutline, mdiArrowLeft } from '@mdi/js'

  let stripeSDK

  export default {

    name: 'Checkout',

    mixins: [ validationMixin ],

    components: {
      PleaseWait,
      AddressBookSelect
    },

    data: () => ({
      componentLoaded: false,
      icon: {
        mdiArrowLeft,
        mdiTrashCanOutline
      },
      stripe: {
        pk: process.env.STRIPE_PK
      },
      step: 1,
      info: {
        name: null,
        email: null,
        billingAddressUlid: '',
        shippingAddressUlid: ''
      },
      payment: {
        cardHolder: '',
        cardElement: null,
        cardError: false,
        stripeCardID: null
      },
      orders: {},
      products: {},
      merchants: {},
      merchantAddresses: {},
      setupIntent: null,
      confirm: {
        active: false,
        description: "",
        onConfirm: null
      },
      serverLoading: false
    }),

    validations: {
      info: {
        billingAddressUlid: { required },
        shippingAddressUlid: { required }
      },
      payment: {
        cardHolder: { required }
      }
    },

    computed: {
      ...mapGetters([
        'address',
        'getAddress',
        'cart',
        'error',
        'loading',
      ]),
      hasOrders() { return Boolean(
        Object.values(this.orders).map(
          order => order.items
        ).flat().length
      ) },
      selectedAddress() {
        return this.info.shippingAddressUlid
          && this.getAddress(this.info.shippingAddressUlid)
      },
      hasAllOrderableItems() {
        return Object.values(this.orders).every(
          o => o.items.every(i => i.isOrderable)
        )
      },
    },

    watch: {
      user: {
        immediate: true,
        handler(value) {
          if (value) {
            this.info.account = value.account
            this.info.name = value.fullName
            this.info.email = value.email
          }
        }
      },
      address: {
        immediate: true,
        handler(value) {
          if (value && value.default) {
            this.info.billingAddressUlid = value.default.ulid
            this.info.shippingAddressUlid = value.default.ulid
          }
        }
      },
      cart: {
        immediate: true,
        handler(value) {
          if (value && Object.keys(this.orders).length < 1) {
            Promise.all([
              this.createOrdersByMerchant(),
              this.$loadScript("https://js.stripe.com/v3/")
            ])
              .then(() => {
                stripeSDK = window.Stripe(this.stripe.pk)
                const elements = stripeSDK.elements();
                this.payment.cardElement = elements.create('card')
                this.componentLoaded = true
              })
          }
        }
      }
    },

    activated() {
      this.$store.dispatch('clearError')
    },

    deactivated() {
      this.componentLoaded = false
      this.$v.$reset()
      this.orders = {}
      this.products = {}
      this.merchants = {}
      this.merchantAddresses = {}
      this.createdOrderIDs = []

      if (this.setupIntent !== null && 'id' in this.setupIntent) {
        fb.func.httpsCallable('stripeCancelSetupIntent')({
          stripeSetupIntentID: this.setupIntent.id
        })
      }
    },

    methods: {

      ...mapActions([
        'connectUserToStripe'
      ]),

      getMerchants(merchants) {
        return merchants.map(merchant =>
          this.$store.dispatch('getAccount', merchant)
            .then(account => {
              this.merchants[merchant] = account

              return this.$store.dispatch('getAccountAddresses', merchant)
            })
            .then(addresses =>
              this.merchantAddresses[merchant] = addresses && addresses.filter(
                add => add.collectionAvailable
              ).map(add => ({
                text:
                  'Collect from: ' +
                  (add.city ? add.city + ', ' : '') +
                  (add.state ? add.state + ' ' : '') +
                  (add.postalCode ? add.postalCode + ', ' : '') +
                  (add.country ? this.$store.getters.territory(add.country) : ''),
                value: 'collection-' + add.ulid
              }))
            )
        )
      },

      createOrdersByMerchant() {
        const items = this.cart.items.concat()
        const merchantArray = [...new Set(items.map(item => item.account))]

        this.orders = {}
        return Promise.all(this.getMerchants(merchantArray))
          .then(() => {
            items
              .sort((a, b) => {
                if (a.account < b.account) return -1
                if (a.account > b.account) return 1
                return 0
              })
              .sort((a, b) => {
                if (a.currency < b.currency) return -1
                if (a.currency > b.currency) return 1
                return 0
              })
              .forEach((item, index) => {
                const merchantCurrencyLabel = String(item.currency + item.account)
                const merchant = this.merchants[item.account]

                if (
                  merchant.status === accountStatus.ACTIVE &&
                  merchant.isStripeConnected && (
                    merchant.shipping !== 'unavailable' ||
                    merchant.collection !== 'unavailable'
                  )
                ) {
                  if (!(merchantCurrencyLabel in this.orders)) {
                    this.$set(this.orders, merchantCurrencyLabel, {
                      merchant: merchant.toOrderObj(item.currency),
                      items: []
                    })
                  }

                  const product = this.products[item.id] = Product.fromObj(item)
                  const isInternational = this.isMerchantInternational(merchant)
                  const isOrderable = (
                    isInternational
                    && merchant.intShipping
                    && merchant.intShipping !== 'unavailable'
                  )

                  const temp = {
                    ...item,
                    productID: item.id,
                    cartIndex: index,
                    shippingOption:
                      product.shipping !== 'unavailable' ?
                        'shipping' : false,
                    shippingRate: product.getProductShippingRate(
                      item.currency, merchant
                    ),
                    isInternational,
                    isOrderable
                  }

                  delete temp.id
                  delete temp.created
                  delete temp.updated

                  const merchantOrderItem = MerchantOrderItem.fromObj(temp)

                  this.orders[merchantCurrencyLabel].items.push(merchantOrderItem)
                }
            })
          })
      },

      optionsOutput(item) {
        return this.products[item.productID]
          .customOptions.reduce((acc, currentValue, index, array) => {
            acc += item.options[currentValue.description]
            acc += index < array.length - 1 ? ', ' : ''

            return acc
          }, "")
      },

      isStandardShipping(item, merchant) {
        return Boolean(
          item &&
          (item.isInternational && merchant.intShipping === 'standard') ||
          !item.isInternational
        )
      },

      getShippingOptions(item) {
        const options = []

        if (item.productID in this.products) {
          const product = this.products[item.productID]
          const merchant = this.merchants[item.account]

          if (
            product.isCollectionAvailable(merchant)
            && item.account in this.merchantAddresses
            && this.merchantAddresses[item.account].length
          ) {
            this.merchantAddresses[item.account].forEach(
              address => options.push(address)
            )
          }

          if (merchant.shipping !== 'unavailable') {
            options.push({
              text: 'Deliver',
              value: 'shipping'
            })
          }
        }

        return options
      },

      isMerchantInternational(merchant) {
        return Boolean(
          this.selectedAddress &&
          this.selectedAddress.country !== merchant.taxRegion
        )
      },

      getMerchantShippingOptions(rates) {
        return rates.map((rule, index) => ({
          text: rule.title + ' - ' + new Intl.NumberFormat(
            navigator.language || 'en', {
              style: 'currency',
              currency: rule.currency
            }).format(rule.price),
          value: index
        }))
      },

      getMerchantShippingTotal(merchant) {
        let merchantShippingTotal
        const merchantTotal = merchant.items.reduce(
          (acc, item) => acc + item.itemTotal, 0
        )

        const isInternational = this.isMerchantInternational(merchant.merchant)

        if (
          (isInternational && merchant.merchant.intShipping === 'standard')
          || !isInternational
        ) {
          merchantShippingTotal = merchant.items.reduce(
            (acc, item) => acc + (
              this.isStandardShipping(item, merchant.merchant)
              && !(item.shippingOption.startsWith('collection'))
              && item.shippingCost
            ), 0
          )

          switch (merchant.merchant.shipping) {
            case "flat": {
              const rule = merchant.merchant.shippingRates[merchant.merchant.shippingOption]
              merchantShippingTotal += (rule && Number.parseFloat(rule.price)) || 0
              break
            }

            case "price": {
              const rule = merchant.merchant.shippingRates.find(
                rate =>
                  merchantTotal > Number.parseFloat(rate.minimum)
                  && merchantTotal <= Number.parseFloat(rate.maximum)
              )
              merchantShippingTotal += (rule && Number.parseFloat(rule.price)) || 0
              break
            }

            default: {
              break
            }
          }
        } else {
          merchantShippingTotal = 0
        }

        merchant.merchant.shippingCost = merchantShippingTotal

        return merchant.merchant.shippingCost
      },

      getMerchantTotal(merchant) {
        merchant.merchant.merchantOrderTotal = merchant.items.reduce(
          (acc, item) => acc + item.itemTotal, 0
        ) + this.getMerchantShippingTotal(merchant)

        return merchant.merchant.merchantOrderTotal
      },

      formatPrice(currency, amount) {
        return Number.isNaN(amount) ?
          'Error' : new Intl.NumberFormat(
            navigator.language || 'en', {
              style: 'currency',
              currency: currency
            }).format(amount)
      },

      getTotals() {
        return Object.values(this.orders).reduce((totals, merchant) => {
          const currency = merchant.merchant.currency
          const merchantTotal = this.getMerchantTotal(merchant)

          if (currency in totals) {
            totals[currency] += merchantTotal
          } else {
            totals[currency] = merchantTotal
          }

          return totals
        }, {})
      },

      getTotalsOutput() {
        return Object
          .entries(this.getTotals())
          .reduce((acc, entry) =>{
            const [ currency, amount ] = entry
            const money = Number.isNaN(amount) ?
              `Error (${currency})` :
              new Intl.NumberFormat(
                navigator.language || 'en', {
                  style: 'currency',
                  currency: currency
                }).format(amount)
            return acc += `${money}<br/>`
          }, '')
      },

      errorState(account, index) {
        if (
          !this.$refs ||
          !this.$refs[`${account}-qty`] ||
          !this.$refs[`${account}-qty`][index]
        ) { return false }

        const value = this.$refs[`${account}-qty`][index].value
        const item = this.cart.items[index]
        const stock = item && this.products[item.id].deriveStock(item.options) || 0

        return (
          !value ||
          isNaN(value) ||
          value > stock
        )
      },

      errorRequired(field, label) {
        return field.$dirty && !field.required ? `${label} is required.` : ''
      },

      updateItem(account, index, quantity) {
        if (!this.errorState(account, index)) {
          this.$store.dispatch('updateCartItem', {
            index,
            quantity
          })
        }
      },

      removeOrderItem(account, index) {
        const refItemID = this.orders[account].items[index].productID
        const cartIdx = this.cart.items.findIndex(
          item => item.id === refItemID
        )
        if (cartIdx >= 0) {
          this.$store.dispatch('removeCartItem', cartIdx)
        }
        this.orders[account].items.splice(index, 1);
        if (this.orders[account].items.length === 0) {
          delete this.orders[account]
        }
      },

      resetConfirm() {
        this.confirm = {
          active: false,
          description: "",
          onConfirm: null
        }
      },

      confirmRemove(index, account, description) {
        const self = this

        this.confirm = {
          active: true,
          description: description,
          onConfirm: () => {
            self.removeOrderItem(account, index)
            self.resetConfirm()
          }
        }
      },

      async submitStep1() {
        this.$v.info.$touch()
        if (!this.$v.info.$invalid) {
          this.$store.dispatch('setLoading', true)

          let response

          if (this.user.stripeCustomerID) {
            response = await fb.func.httpsCallable(
              'stripeUpdateCustomer'
            )({
              stripeCustomerID: this.user.stripeCustomerID,
              email: this.info.email,
              name: this.info.name,
              billing:
                this.$store.getters.getAddress(this.info.billingAddressUlid),
              shipping:
                this.$store.getters.getAddress(this.info.shippingAddressUlid)
            })

            this.logInfo('Updated Stripe customer details')
          } else {
            response = await fb.func.httpsCallable(
              'stripeCreateCustomer'
            )({
              email: this.info.email,
              name: this.info.name,
              billing:
                this.$store.getters.getAddress(this.info.billingAddressUlid),
              shipping:
                this.$store.getters.getAddress(this.info.shippingAddressUlid)
            })

            this.logInfo('Created Stripe customer details')

            this.connectUserToStripe(response.data.id)

            this.logInfo('Linked Stripe customer')
          }

          // TODO: cancel abandoned setupIntent
          response = await fb.func.httpsCallable('stripeSetupIntent')({
            stripeCustomerID: this.user.stripeCustomerID
          })

          this.setupIntent = response.data

          this.logInfo('Created setupIntent')

          this.$store.dispatch('setLoading', false)
          this.step = 2
          this.payment.cardElement.mount("#card-element")
          this.payment.cardElement.on('change', event =>
            this.payment.cardError = Boolean(event.error)
          )
        }
      },

      async submitStep2() {
        this.$v.payment.$touch()
        if (!this.$v.payment.$invalid) {
          this.$store.dispatch('setLoading', true)

          const response = await stripeSDK.confirmCardSetup(
            this.setupIntent.client_secret,
            {
              payment_method: {
                card: this.payment.cardElement,
                billing_details: {
                  name: this.payment.cardHolder
                }
              }
            }
          )

          this.$store.dispatch('setLoading', false)

          if (response.error) {
            this.payment.cardElement._invalid = true
          } else {
            this.logInfo('Confirmed setupIntent card setup')
            this.payment.stripeCardID = response.setupIntent.payment_method
            this.setupIntent = null
            this.step = 3
          }
        }
      },

      async submitStep3() {
        this.$v.$touch()
        if (!this.$v.$invalid && this.hasAllOrderableItems) {
          this.serverLoading = true
          this.$store.dispatch('setLoading', true)

          const postData = {
            language: navigator.language || 'en',
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            customer: {
              id: this.user.uid,
              name: this.info.name,
              email: this.info.email,
              stripeCustomer: this.user.stripeCustomerID
            },
            payment: {
              card: this.payment.stripeCardID
            },
            orders:
              Object.entries(this.orders)
                .reduce((merchant, [key, merchantOrder]) => {
                  merchant[key] = {
                    'merchant': merchantOrder.merchant,
                    'items': merchantOrder.items
                      .map(item => item.toOrderObj())
                  }
                  return merchant
                }, {})
          }

          // Just in case: console.log(JSON.stringify(postData))

          const response = await fb.func.httpsCallable('checkOut')(postData)
          const savedOrders = response.data

          this.logInfo('Successfully created orders and payments')

          await Promise.all(
            savedOrders.map(order => {
              fb.analytics.logEvent('purchase', {
                currency: order.merchant.currency,
                items: order.items.map(item => ({
                  id: item.id,
                  name: item.title,
                  price: item.unitPrice,
                  quantity: item.quantity
                })),
                transaction_id: order.orderID,
                value: order.merchant.merchantOrderTotal
              })
              return fb.func.httpsCallable('sendNewOrderEmail')(order)
            })
          )

          this.serverLoading = false
          this.$store.dispatch('setLoading', false)

          this.$store.dispatch('clearError')
          this.$store.dispatch('clearCart')

          const orderIDs = savedOrders
            .map(order => order && order.orderID)
            .filter(Boolean)

          this.$router.push({
            name: 'checkout-complete',
            query: { orders: orderIDs.join(',') }
          })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~vuetify/src/styles/settings/_variables';

  .theme--light.v-expansion-panels.v-expansion-panels--focusable .v-expansion-panel-header--active::before {
    opacity: 0;
  }

  .vlic-data {
    flex-basis: 70%;
  }
  .vlic-stock {
    text-align: right;
  }

  .vlic-total {
    text-align: right;
    flex: 0 0 40% !important;
  }

  .vlic-shipping {
    flex: 0 0 60%;
    max-width: 60%;
  }

  .StripeElement {
    color: #666666;
    padding-top: 12px;
    padding-bottom: 4px;
    margin-top: 4px;
    margin-bottom: 20px;

    border-color: currentColor;
    border-style: solid;
    border-width: 0 0 thin 0;
  }

  .StripeElement--focus {
    color: #1976d2;
    border-bottom-width: 2px;
  }

  .StripeElement--invalid {
    color: #ff5252;
  }

  .v-alert.stripe-notice {
    background: url(/img/stripe-grey.svg) 16px center no-repeat !important;
    background-size: 80px auto !important;
    padding: 16px 16px 16px 112px;
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    .v-list-item {
      flex-wrap: wrap;
    }
    .v-alert.stripe-notice {
      background: url(/img/stripe-grey.svg) center 16px no-repeat !important;
      background-size: 80px auto !important;
      padding: 60px 16px 16px 16px;
    }
  }
</style>
