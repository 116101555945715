<template>
  <v-card>
    <v-toolbar class="page-title" flat dark color="#7070a0">
      <v-toolbar-title><h1 class="title">Checkout complete</h1></v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <p>Thank you for your business! As a result, the following merchant
      orders have been created:</p>

      <div
        v-for="(order, index) in orders"
        :key="index"
      >
        <v-toolbar flat dark color="#7070a0">
          <v-toolbar-title>{{ order.merchant ? order.merchant.legalName : '' }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                Order ID:
              </v-list-item-content>
              <v-list-item-content class="justify-end text-right">
                <strong>{{ order.orderID }}</strong>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                Total:
              </v-list-item-content>
              <v-list-item-content class="justify-end text-right">
                <strong>{{ displayTotal(order) }}</strong>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </div>
    </v-card-text>
    <v-card-actions>
      <div class="flex-grow-1"></div>
      <v-btn
        depressed
        color="success"
        :to="{ name: 'stream' }"
      >Continue browsing Nuokka</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  const zeroRated = [
    'bif', 'clp', 'djf', 'gnf', 'jpy', 'kmf', 'krw', 'mga',
    'pyg', 'rwf', 'ugx', 'vnd', 'vuv', 'xaf', 'xof', 'xpf'
  ]

  export default {
    name: 'CheckoutComplete',

    data() {
      return {
        orders: []
      }
    },

    mounted() {
      this.$store.dispatch(
        'findOrders', this.$route.query.orders.split(',')
      ).then(orders => this.orders = orders)
    },

    methods: {
      currencyFactor: (currency) => currency in zeroRated ? 1 : 100,
      displayTotal(order) {
        return new Intl.NumberFormat(
          navigator.language || 'en', {
            style: 'currency',
            currency: order.payment.currency
          }).format(
            order.payment.amount /
            this.currencyFactor(order.payment.currency)
          )
      }
    }
  }
</script>
