<template>
  <v-card>
    <v-toolbar class="page-title" flat dark color="#7070a0">
      <v-toolbar-title><h1 class="title">Address book</h1></v-toolbar-title>
      <v-toolbar-title v-if="user" class="text-right">{{ user.account }}</v-toolbar-title>
    </v-toolbar>
    <v-card-text
      v-if="!address || !address.book || !address.book.length"
      class="text-center subtitle-1 my-4"
    >
      <p>Your address book is currently empty.</p>
    </v-card-text>
    <v-list v-else>
      <template v-for="(item, index) in address.book">
        <v-list-item
          :key="'address'+index"
          :value="item"
          class="justify-end"
        >
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">{{ item.description }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ item.contact }}, {{ item.line1 }}, {{
                item.line2 ? item.line2 + ', ' : '' }}{{
                item.city ? item.city + ', ' : '' }}{{
                item.state ? item.state + ', ' : '' }}{{
                item.postalCode ? item.postalCode + ', ' : '' }}{{
                $store.getters.territory(item.country) }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="d-flex">
              <div class="flex-grow-1 pa-2">
                <div class="text-center" v-if="item.isDefault"><v-icon color="success">{{ icon.mdiCheckBoxOutline }}</v-icon> Default</div>
              </div>
              <div class="flex-grow-1 pa-2">
                <div class="text-center" v-if="item.collectionAvailable"><v-icon color="success">{{ icon.mdiCheckBoxOutline }}</v-icon> Collection</div>
              </div>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="text-right">
              <v-btn-toggle
                dense
                multiple
              >
                <v-btn icon :to="{name: 'account-address-edit', params: {'account': user.account, 'ulid': item.ulid}}">
                  <v-icon>{{ icon.mdiFileDocumentEditOutline }}</v-icon>
                </v-btn>
                <v-btn icon @click.stop="onDelete(item)">
                  <v-icon>{{ icon.mdiTrashCanOutline }}</v-icon>
                </v-btn>
              </v-btn-toggle>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          :key="'tools'+index"
        >
        </v-list-item>
        <v-divider
          v-if="index + 1 < address.book.length"
          :key="'divider' + index"
        ></v-divider>
      </template>
    </v-list>
    <v-card-actions>
      <v-btn dark depressed color="#7070a0" :to="{name: 'account-address-add', params: {'account': user.account}}">Add address</v-btn>
      <div class="flex-grow-1"></div>
      <v-btn :to="{name: 'profile', params: {account: user.account}}" dark depressed color="#7070a0">Done</v-btn>
    </v-card-actions>
    <v-dialog
      v-model="confirm.active"
      max-width="400"
    >
      <v-card>
        <v-card-title class="headline">Are you sure?</v-card-title>

        <v-card-text>
          Do you really want to delete the address "<span class="font-weight-bold">{{ confirm.description }}</span>"?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="resetConfirm">No</v-btn>
          <v-btn text @click.stop="confirm.onConfirm">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
  import {
    mdiCheckBoxOutline,
    mdiFileDocumentEditOutline,
    mdiTrashCanOutline
  } from '@mdi/js'

  export default {

    name: 'AddressBook',

    data() {
      return {
        icon: {
          mdiCheckBoxOutline,
          mdiFileDocumentEditOutline,
          mdiTrashCanOutline
        },
        confirm: {
          active: false,
          description: "",
          onConfirm: null
        }
      }
    },

    computed: {
      address() {
        return this.$store.getters.address
      }
    },

    methods: {
      onDelete(address) {
        this.confirmDelete(address.ulid, address.description)
      },
      resetConfirm() {
        this.confirm = {
          active: false,
          description: "",
          onConfirm: null
        }
      },
      confirmDelete(ulid, description) {
        const self = this

        this.confirm = {
          active: true,
          description: description,
          onConfirm: () => {
            self.$store.dispatch('deleteAddress', ulid)
            self.resetConfirm()
          }
        }
      }
    }
  }
</script>

<style lang="scss">
</style>
